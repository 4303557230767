@import '../../../../styles/common/currencyForm.scss';
@import '../../../../styles/common/multiSelect.scss';
@import '../../../../styles/common/textForm.scss';
@import '../../../../styles/common/datePicker.scss';

#export-all-donation-by-filter-date-filter {
  height: 100%;
  color: var(--color-text-primary);
  .pl-5 {
    padding-left: 5px;
  }
  > div {
    height: auto;
  }
  svg {
    color: var(--color-text-primary);
  }
  .border-b {
    border-bottom: none;
    h3 {
      color: var(--color-text-primary);
      text-align: center;
      width: calc(100% - 32px);
      padding-left: 32px;
      padding-top: 0;
    }
    button {
      margin-top: -1rem;
      margin-right: -1rem;
      &:hover {
        background-color: var(--color-button-blue);
        box-shadow: none;
        svg {
          transition: all 0.5s ease;
          color: var(--color-text-white);
        }
      }
    }
  }

  .custom-multi-select {
    @include custom-multi-select;
  }

  .currency-form {
    @include currency-form;
  }
  .text-form {
    @include text-form;
  }
  .date-form {
    @include date-picker;
  }
}

@media (max-width: 640px) {
  #export-all-donation-by-filter-date-filter {
    flex-wrap: wrap;
    height: 100%;
    .form-flex-col {
      flex-direction: column;
      margin: unset;
    }
    .form-input {
      width: 100%;
      padding: unset;
      margin: unset;
      margin-bottom: 10px;
    }
  }
}
