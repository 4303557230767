.main-layout {
  .min-h-screen {
    min-height: 100vh;
  }

  button {
    height: 36px;
    border-radius: 4px;

    &.bg-blue-700 {
      background-color: var(--color-background-white);
      border: 1px solid var(--color-button-blue);
      color: var(--color-button-blue);

      &:focus {
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--color-button-blue-focus);
      }

      &:hover {
        background: var(--color-button-blue);
        color: var(--color-text-white);
      }
    }

    &.bg-blue-900 {
      background: var(--color-button-blue);
      color: var(--color-text-white);

      &:focus {
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--color-button-blue-focus);
      }

      &:hover {
        background: transparent;
        border: 1px solid var(--color-button-blue);
        color: var(--color-button-blue);
      }
    }

    &.bg-red-700 {
      &:focus {
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--color-button-red-focus);
      }

      background: transparent;
      border: 1px solid var(--color-button-red);
      color: var(--color-button-red);

      &:hover {
        background: var(--color-button-red);
        color: var(--color-text-white);
      }
    }

    &.bg-gray-custom {
      background: var(--color-button-gray);
      border: 1px solid var(--color-button-gray);
      color: var(--color-text-white);

      &:focus {
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--color-button-gray-focus);
      }

      &:hover {
        background: transparent;
        border: 1px solid var(--color-button-gray);
        color: var(--color-button-gray);
      }
    }
  }

  .add-action {
    button {
      &.bg-blue-700 {
        &:focus {
          --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--color-button-blue-focus);
        }

        background: var(--color-button-blue);
        color: var(--color-text-white);

        &:hover {
          background: transparent;
          border-color: var(--color-button-blue);
          color: var(--color-button-blue);
        }
      }
    }
  }

  .actions button {
    height: 36px;
  }
}

@media screen and (max-width: 1024px) {
  table {
    th {
      button {
        width: 36px;
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
      }
    }
  }
}
