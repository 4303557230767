.portfolio-report-pdf {
   padding: 10px;
  .pie-chart-wrapper {
    margin: 15px;
  }
  .recharts-wrapper {
    position: relative !important;
    width: 100% !important;
    height: fit-content !important;
    flex-direction: column;

    svg {
      position: static !important;
    }

    > svg {
      .recharts-pie {
        .recharts-layer {
          &.recharts-pie-labels {
            .recharts-layer {
              > path {
                display: none;
              }
            }
          }
        }
      }
    }
    .recharts-legend-wrapper {
      width: 100% !important;
      height: fit-content !important;
      flex-direction: column;
      top: auto !important;
      bottom: 0;
      > ul {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 0.5rem;
      }
    }
  }
  .portfolio-report__table {
    padding: 0px;
    table {
      width: 100%;
      max-width: 100%;
      font-size: 60%;
      .max-w-full {
        padding: 0 5px;
        width: unset;
        &[data-tooltip-id="portfolio-report-rating-date-0"] {
          width: max-content;
          padding-right: 5px;
        }
      }

      th {
        padding: 16px 0;
        margin: 0;
        height: auto;
        width: auto;
        font-weight: 600;
        &:first-child {
          padding-left: 10px;
        }
        &:last-child {
          padding-right: 10px;
        }
      }
    }
  }

  input {
    background: unset;
    border: unset;
    padding: 0;
  }
  .card-index-group {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
