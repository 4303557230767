.bullet {
  width: 7px;
  height: 7px;
  border-radius: 20px;
}
.hide-status {
  display: none;
}
.veri-modal {
  overflow: hidden;
  transition: height 0.2s;
  height: 0px;
}

.height-80 {
  height: 80px;
}

.height-16 {
  height: 16px;
}

[type='text']:focus,
[type='time']:focus,
[type='url']:focus,
[type='week']:focus,
select:focus,
textarea:focus {
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  border-color: transparent;
  box-shadow: none;
  outline: 0 solid transparent;
}
