@import '../_mixin';

@mixin custom-async-select {
  .css-1jqq78o-placeholder {
    display: none;
  }
  &.disabled {
    background-color: var(--color-background-disabled-input);
    pointer-events: none;
  }

  &__label {
    top: 0.85rem;
    &.haveValue {
      display: block;
      top: -13px;
      left: 10px;
      label {
        color: var(--color-text-grey-light);
        font-size: 12px;
      }
      &.disabled {
        background-color: var(--color-background-disabled-input);
        border-radius: 4px;
        &.haveValue {
          background-color: var(--color-background-white);
        }
      }
    }
    pointer-events: none;
    left: 10px;
    padding: 0 5px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    &.disabled {
      background-color: var(--color-background-disabled-input);
    }
    label {
      font-weight: normal;
      color: rgba(107, 114, 128, 1);
    }
  }

  .open-menu-icon {
    width: 24px;
    height: 22px;
    border-radius: 4px;
    transition: all 0.5s ease;
    cursor: pointer;
    &:hover {
      background-color: var(--color-button-blue);
      color: var(--color-text-white);
      box-shadow: none;
    }
  }

  &:focus-within {
    .custom-async-select__label {
      display: block;
      top: -13px;
      label {
        color: var(--color-text-grey-light);
        font-size: 12px;
      }
    }
  }

  .async-select {
    &.disabled {
      .css-13cymwt-control,
      .css-t3ipsp-control {
        background-color: var(--color-background-disabled-input);
        .dropdown-heading-value__no-content {
          background-color: var(--color-background-disabled-input);
        }
      }
    }
    &.error {
      .css-13cymwt-control,
      .css-t3ipsp-control {
        border-color: red;
        box-shadow: none;
        &:focus-within {
          box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
          --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) red;
        }
      }
    }
    &.single-select {
      .options {
        .select-item {
          .item-renderer {
            input {
              display: none;
            }
          }
        }
      }
    }

    .css-13cymwt-control,
    .css-t3ipsp-control {
      display: flex;
      align-items: center;
      border: 1px solid #e5e7eb;
      font-size: 0.875rem;
      min-height: 54px;
      line-height: 1.25rem;
      background-color: var(--color-background-white);
      .css-1fdsijx-ValueContainer,
      .css-hlgwow {
        padding-top: 0.93rem;
        padding-bottom: 0.93rem;
        .css-1dimb5e-singleValue {
          padding-left: 4px;
        }
      }
      .clear-selected-button {
        svg {
          display: none;
        }
      }
      .css-1hb7zxy-IndicatorsContainer,
      .css-1wy0on6 {
        .css-1u9des2-indicatorSeparator {
          display: none;
        }
        .css-pogzpp-loadingIndicator {
          display: none;
        }
        .css-1xc3v61-indicatorContainer,
        .css-15lsz6c-indicatorContainer {
          display: none;
        }
      }
    }
    .css-1nmdiq5-menu,
    .css-qr46ko {
      .async-select-panel {
        .panel-search {
          display: flex;
          justify-content: space-between;
          height: 38px;
          color: var(--color-text-primary);
          font-size: 14px;
          font-weight: 400;
          &:focus-within {
            background-color: #f9fafb;
          }
          &-input {
            background: none;
            height: 100%;
            padding: 0 10px;
            width: 100%;
            outline: 0;
            border: 0;
            font-size: 1em;
            &:focus {
              box-shadow: none;
            }
          }
          &-clear-button {
            width: 29px;
            height: 24px;
            padding: auto;
            margin: auto;
            margin-right: 8px;
            .search-clear-icon {
              color: var(--color-text-primary);
              box-sizing: border-box;
              transition: all 0.5s ease;
              border-radius: 4px;
              cursor: pointer;
              &:hover {
                background-color: var(--color-button-blue);
                color: var(--color-text-white);
                box-shadow: none;
              }
            }
          }
        }
      }
    }
    .css-1n6sfyn-MenuList,
    .css-qr46ko {
      max-height: 240px;
      @include scrollYBem;

      &__no-content {
        display: none;
      }
      .infinite-scroll-component__outerdiv {
        .infinite-scroll-component {
          .menu-list-item {
            &:hover {
              background-color: #eef6f6;
            }
            &:active {
              background-color: none;
            }
            &.selected {
              background-color: #eef6f6;
            }
            font-weight: 400;
            float: left;
            width: 100%;
            word-wrap: break-word;
            white-space: initial;
            color: var(--color-text-primary);
            padding: 10px;
            span {
              font-size: 14px;
              line-height: 14px;
            }
          }
        }
      }
    }
  }

  .loading-indicator {
    display: flex;
    -webkit-transition: color 150ms;
    transition: color 150ms;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    font-size: 4px;
    line-height: 1;
    margin-right: 4px;
    text-align: center;
    vertical-align: middle;
    color: hsl(0, 0%, 40%);
    padding: 8px;
    box-sizing: border-box;
    .loading-dot-first {
      -webkit-animation: animation-stj4i2 1s ease-in-out 0ms infinite;
      animation: animation-stj4i2 1s ease-in-out 0ms infinite;
      background-color: currentColor;
      border-radius: 1em;
      display: inline-block;
      height: 1em;
      vertical-align: top;
      width: 1em;
    }
    .loading-dot-second {
      -webkit-animation: animation-stj4i2 1s ease-in-out 160ms infinite;
      animation: animation-stj4i2 1s ease-in-out 160ms infinite;
      background-color: currentColor;
      border-radius: 1em;
      display: inline-block;
      margin-left: 1em;
      height: 1em;
      vertical-align: top;
      width: 1em;
    }
    .loading-dot-third {
      -webkit-animation: animation-stj4i2 1s ease-in-out 320ms infinite;
      animation: animation-stj4i2 1s ease-in-out 320ms infinite;
      background-color: currentColor;
      border-radius: 1em;
      display: inline-block;
      margin-left: 1em;
      height: 1em;
      vertical-align: top;
      width: 1em;
    }
  }
}
