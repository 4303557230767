.investment-detail-page {
  background-color: var(--color-background-sub-screen);

  .investment-payment-detail {
    &__top {
      .card-index-group {
        .bg-teal-100 {
          background-color: #ccfbf1;
        }

        .bg-teal-500 {
          background-color: #06b6d4;
        }

        .card-index__container {
          flex: 1;
        }
      }
    }

    &__bottom {
      .investment-payment {
        &__header {
          &--tabs {
            width: 100%;
            border: none;
            gap: 0;
            flex: 1;

            [role='tablist'] {
              max-height: 41px;
            }

            &.gap-2 {
              height: 100%;
            }

            & > div + div {
              height: 100%;

              [role='tabpanel'] {
                &[hidden] {
                  display: none;
                }
              }

              [role='tabpanel'] {
                padding: 0;
                height: 100%;
                display: flex;
                flex-direction: column;

                &[hidden] {
                  display: none;
                }

                table {
                  button {
                    height: auto;
                    width: auto;
                  }

                  .max-width-80 {
                    max-width: 80px;
                    min-width: 60px;
                  }

                  .max-width-108 {
                    max-width: 108px;
                    min-width: 90px;
                  }

                  .max-width-140 {
                    max-width: 140px;
                    min-width: 90px;
                  }

                  thead {
                    position: sticky;
                    z-index: 1;
                    top: 0;
                    text-transform: capitalize;
                    color: var(--color-text-primary);
                    height: 46px;
                  }

                  tbody {
                    tr {
                      height: 54px;

                      &.investment-payment-last {
                        border-bottom: none;
                      }

                      th {
                        color: var(--color-text-primary);

                        button {
                          width: 70px;
                          max-height: 30px;
                          border-radius: 4px;
                          padding: 0.5rem;
                          object-fit: cover;

                          &.download-pdf-button {
                            max-width: 30px;
                            margin-left: 5px;
                          }
                          &.delete-payment-button {
                            max-width: 30px;
                            margin-left: 5px;
                          }
                        }
                        &.group-button {
                          display: flex;
                        }

                        .date-form {
                          max-width: 250px;
                        }
                      }
                      .final-investment-text {
                        font-weight: 700;
                        line-height: 14px;
                      }

                      &.investment-payment-no-result {
                        th {
                          img {
                            width: 51px;
                          }

                          .text-gray-700 {
                            font-size: 16px;
                            color: var(--color-text-grey-light);
                          }

                          .text-gray-900 {
                            font-size: 26px;
                            color: var(--color-text-primary);
                          }
                        }
                      }
                    }
                  }

                  tfoot {
                    text-transform: capitalize;
                    color: var(--color-text-primary);
                    height: 46px;
                  }

                  .investment-payment--truncate {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &.max-width-80 {
                      max-width: 80px;
                      min-width: 60px;
                    }
                  }
                }

                .investment-payment__pagination {
                  flex: 1;
                  background-color: var(--color-text-white);
                  align-items: end;

                  .text-gray-700 {
                    color: var(--color-text-primary);
                    font-size: small;
                  }

                  .text-gray-900 {
                    color: var(--color-button-blue);
                    font-size: small;
                  }

                  &--container {
                    height: 60px;
                    border-top: 1px solid rgb(209 213 219 / var(--tw-border-opacity));
                  }
                }
              }
            }

            [role='tablist'] {
              button {
                padding-top: 20px;
                padding-bottom: 20px;
                border-radius: 0;

                &:hover {
                  border: none;
                }

                &.bg-gray-100 {
                  background-color: var(--color-text-white);
                }
              }
            }
          }

          &--paymentSchedule {
            display: flex;
            flex-direction: column;

            .add-icon {
              display: block;
              height: auto;
              top: 0.35rem;
            }
            .pay-off-button {
              display: block;
              height: auto;
              top: 0.35rem;
              right: 35px;
            }

            .recalculate-button {
              display: flex;
              height: 26px;
              top: 0.35rem;
              right: 2.3rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .investment-detail-page {
    .investment-payment-detail {
      &__top {
        .card-index-group {
          grid-template-columns: repeat(1, minmax(0, 1fr));

          .bg-teal-100 {
            background-color: #ccfbf1;
          }

          .bg-teal-500 {
            background-color: #06b6d4;
          }
        }
      }

      &__bottom {
        .investment-payment {
          &__header {
            &--tabs,
            .have-collateral {
              & > div + div {
                height: 100%;
              }
            }

            .date-form {
              max-width: 100% !important;
            }
          }

          &__pagination {
            &--container {
              .hidden-mobile {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 641px) and (max-width: 820px) {
  .investment-detail-page {
    .investment-payment-detail {
      &__top {
        .card-index-group {
          grid-template-columns: repeat(3, minmax(0, 1fr));

          .bg-teal-100 {
            background-color: #ccfbf1;
          }

          .bg-teal-500 {
            background-color: #06b6d4;
          }

          .card-index__container {
            flex: 1;
          }
        }
      }

      &__bottom {
        .investment-payment {
          &__header {
            padding-top: 0;

            &--tabs,
            .have-collateral {
              & > div + div {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 821px) and (max-width: 1280px) {
  .investment-detail-page {
    .investment-payment-detail {
      &__top {
        .card-index-group {
          grid-template-columns: repeat(4, minmax(0, 1fr));

          .bg-teal-100 {
            background-color: #ccfbf1;
          }

          .bg-teal-500 {
            background-color: #06b6d4;
          }

          .card-index__container {
            flex: 1;
          }
        }
      }

      &__bottom {
        .investment-payment {
          &__header {
            padding-top: 0;

            &--tabs,
            .have-collateral {
              & > div + div {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1281px) {
  .investment-detail-page {
    .investment-payment-detail {
      &__top {
        .card-index-group {
          .bg-teal-100 {
            background-color: #ccfbf1;
          }

          .bg-teal-500 {
            background-color: #06b6d4;
          }
        }
      }

      &__bottom {
        .investment-payment {
          &__header {
            padding-top: 0;
          }
        }
      }
    }
  }
}
