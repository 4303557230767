@import '../../../../styles/_mixin';
.pdf-content {
  padding: 15px;
}

.loan-detail-page {
  background-color: var(--color-background-sub-screen);

  .loan-detail {
    &__top {
      .card-index-group {
        .bg-teal-100 {
          background-color: #ccfbf1;
        }

        .bg-teal-500 {
          background-color: #06b6d4;
        }

        .card-index__container {
          flex: 1;
        }
      }
    }

    &__bottom {
      .payment {
        &__header {
          &--tabs {
            width: 100%;
            border: none;
            gap: 0;
            flex: 1;

            [role='tablist'] {
              max-height: 41px;
            }

            &.gap-2 {
              height: 100%;
            }

            @include scrollY;

            & > div + div {
              overflow: auto;
              height: 100%;

              [role='tabpanel'] {
                &[hidden] {
                  display: none;
                }
              }

              [role='tabpanel'] {
                padding: 0;
                height: 100%;
                display: flex;
                flex-direction: column;

                &[hidden] {
                  display: none;
                }

                table {
                  button {
                    height: auto;
                    width: auto;
                  }

                  .max-width-80 {
                    max-width: 80px;
                    min-width: 60px;
                  }

                  .max-width-108 {
                    max-width: 108px;
                    min-width: 90px;
                  }

                  thead {
                    position: sticky;
                    z-index: 1;
                    top: 0;
                    text-transform: capitalize;
                    color: var(--color-text-primary);
                    height: 46px;
                  }

                  tbody {
                    tr {
                      height: 54px;

                      &.payment-last {
                        border-bottom: none;
                      }

                      th {
                        color: var(--color-text-primary);

                        button {
                          max-height: 30px;
                          border-radius: 4px;
                          padding: 10px;
                          object-fit: cover;
                        }

                        .date-form, .currency-input {
                          max-width: 250px;
                        }
                      }

                      &.payment-no-result {
                        th {
                          img {
                            width: 51px;
                          }

                          .text-gray-700 {
                            font-size: 16px;
                            color: var(--color-text-grey-light);
                          }

                          .text-gray-900 {
                            font-size: 26px;
                            color: var(--color-text-primary);
                          }
                        }
                      }
                    }
                  }

                  tfoot {
                    text-transform: capitalize;
                    color: var(--color-text-primary);
                    height: 46px;
                  }

                  .payment--truncate {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &.max-width-80 {
                      max-width: 80px;
                      min-width: 60px;
                    }
                  }
                }

                .payment__pagination {
                  flex: 1;
                  background-color: var(--color-text-white);
                  align-items: end;

                  .text-gray-700 {
                    color: var(--color-text-primary);
                    font-size: small;
                  }

                  .text-gray-900 {
                    color: var(--color-button-blue);
                    font-size: small;
                  }

                  &--container {
                    height: 60px;
                    border-top: 1px solid rgb(209 213 219 / var(--tw-border-opacity));
                    border-bottom: none;
                  }
                }
              }
            }

            [role='tablist'] {
              button {
                padding-top: 20px;
                padding-bottom: 20px;
                border-radius: 0;

                &:hover {
                  border: none;
                }

                &.bg-gray-100 {
                  background-color: var(--color-text-white);
                }
              }
            }
          }

          &--paymentSchedule {
            display: flex;
            flex-direction: column;

            .add-icon {
              display: block;
              height: auto;
              top: 0.35rem;
            }
            .download-pdf-button {
              display: flex;
              height: 26px;
              top: 0.35rem;
              right: 13.3rem;
            }
            .recalculate-button {
              display: flex;
              height: 26px;
              top: 0.35rem;
              right: 2.3rem;
            }
          }
        }
      }
    }

  }
}

@media (min-width: 821px) and (max-width: 1280px) {
  .loan-detail-page {
    .loan-detail {
      &__top {
        .card-index-group {
          grid-template-columns: repeat(4, minmax(0, 1fr));

          .bg-teal-100 {
            background-color: #ccfbf1;
          }

          .bg-teal-500 {
            background-color: #06b6d4;
          }

          .card-index__container {
            flex: 1;
          }
        }
      }

      &__bottom {
        .payment {
          &__header {
            padding-top: 0;

            &--tabs,
            .have-collateral {
              & > div + div {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1281px) {
  .loan-detail-page {
    .loan-detail {
      &__top {
        .card-index-group {
          .bg-teal-100 {
            background-color: #ccfbf1;
          }

          .bg-teal-500 {
            background-color: #06b6d4;
          }
        }
      }

      &__bottom {
        .payment {
          &__header {
            padding-top: 0;
          }
        }
      }
    }
  }
}
