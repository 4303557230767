.investment {
  &__pagination {
    align-items: end;
    .text-gray-700 {
      color: var(--color-text-primary);
    }
    .text-gray-900 {
      color: var(--color-button-blue);
    }
    &--container {
      height: 64px;
      border-top: 1px solid rgb(209 213 219 / var(--tw-border-opacity));
    }
  }
  &__dateInput {
    position: relative;
    bottom: 5px;
  }
}
