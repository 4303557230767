button {
  align-items: center;
  display: flex;

  &.bg-blue-700 {
    background-color: var(--color-background-white);
    border: 1px solid var(--color-button-blue);
    color: var(--color-button-blue);

    &:focus {
      --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--color-button-blue-focus);
    }

    &:hover {
      background: var(--color-button-blue);
      color: var(--color-text-white);
    }
  }

  &.bg-fill-blue-700 {
    background: transparent;
    background-color: var(--color-button-blue);
    color: var(--color-text-white);

    &:focus {
      --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--color-button-blue-focus);
    }

    &:hover {
      border: 1px solid var(--color-button-blue);
      background: var(--color-background-white);
      color: var(--color-button-blue);
    }
  }
}
