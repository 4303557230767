@import '~flowbite';
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  background: #f9fafb;
}
.App {
  min-height: 100%;
}
.overflow-y {
  overflow-y: auto;
}

.wrapper-content {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.p-50 {
  padding: 50px;
}
.mt-50 {
  margin-top: 50px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-20 {
  margin-bottom: 20px;
}
.w-screen {
  width: 435px;
}

body {
  overflow-x: hidden !important;
}
