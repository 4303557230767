@import '../../../../styles/__mixin';

.supporter-detail-page {
  flex: 1;
  background-color: var(--color-background-sub-screen);

  &__container {
    .container-left {

      margin-right: 1rem;
      max-width: 300px;
      width: 33.33%;

      &__info {
        max-height: 522px;
        min-width: 300px;

        #wCCNIsBeneficiary {
          background-color: var(--color-text-white);
          border: 1px solid var(--color-button-blue);

          &.checked {
            background-color: var(--color-button-blue);
            border: none;
          }
        }
      }

      &__note {
        margin-top: 1rem;
        flex: 1 1;
        position: relative;

        &--content {
          @include scrollYBem;

          .content-text {
            &[data-testid='flowbite-tooltip-target'] {
              @include truncate-text
            }

            &.react-tooltip {
              width: fit-content;
              word-wrap: break-word;
              color: white;
            }
          }

          .note-no-result {
            img {
              width: 42px;
            }

            .text-gray-700 {
              font-size: 14px;
              color: var(--color-text-grey-light);
            }

            .text-gray-900 {
              font-size: 20px;
              color: var(--color-text-primary);
            }
          }
        }
      }
    }

    .container-right {
      flex: 1;
      &__two-table {
        flex: 1;
        min-height: calc(100vh - 230px);
        @include scrollYBem;
      }

      button {
        height: auto;
        width: auto;
        padding: 5px !important;
        aspect-ratio: 1/1;
      }

      .max-width-100 {
        max-width: 100px;
        min-width: 90px;
      }

      .max-width-80 {
        max-width: 80px;
        min-width: 70px;
      }

      .donation-last {
        border-bottom: none;
      }

      .investment-last {
        border-bottom: none;
      }
    }
  }
}

@media (max-width: 640px) {
  .supporter-detail-page {
    &__container {
      flex-direction: column;

      .container-left {
        width: 100%;
        max-width: 100%;
        flex-direction: column;
        margin-bottom: 1rem;
        margin-right: 0;

        &__info {
          width: 100%;
          margin-bottom: 1rem;
        }

        &__note {
          height: 100%;
          flex-grow: 1;
          margin: 0;

          &--content {
            max-height: 442px;
            @include scrollYBem;
          }
        }
      }

      .container-right {
        &__two-table {
          min-height: calc(100vh - 700px);
        }

        width: 100%;
      }
    }
  }
}

@media (min-width: 641px) and (max-width: 1280px) {
  .supporter-detail-page {
    &__container {
      flex-direction: column;

      .container-left {
        width: 100%;
        max-width: 100%;
        flex-direction: column;
        margin-bottom: 1rem;
        margin-right: 0;

        &__info {
          max-height: 100%;
          margin-right: 0;
        }

        &__note {
          max-height: 100%;
          height: 100%;
          flex-grow: 1;
          margin-top: 0;

          &--content {
            max-height: 505px;
            @include scrollYBem;
          }
        }
      }

      .container-right {
        &__two-table {
          min-height: calc(100vh - 800px);
        }

        width: 100%;

        .card-index-group {
          margin: 0;
        }
      }
    }
  }
}

@media (min-width: 1281px) {
  .supporter-detail-page {
    &__container {
      .container-right {
        .investment {
          &__body {
            justify-content: start;

            thead {
              position: sticky;
              top: 0;
            }
          }
        }

        .donation {
          &__body {
            justify-content: start;

            thead {
              position: sticky;
              top: 0;
            }
          }
        }
      }
    }
  }
}
