@import '../../../styles/common/textForm.scss';
@import '../../../styles/__mixin';

#add-notes-supporter-modal {
  color: var(--color-text-primary);
  height: 100%;
  overflow-y: hidden;

  > div {
    height: auto;
  }

  .note-content {
    max-height: 278px;
    overflow-y: auto;
    @include scrollYBem;

    &__text {
      position: relative;

      &--truncate {
        &[data-testid='flowbite-tooltip-target'] {
          @include truncate-text
        }

        &.react-tooltip {
          width: fit-content;
          word-wrap: break-word;
          color: white;
        }
      }
    }

    &__icon-edit {
      color: var(--color-button-blue);
      cursor: pointer;
    }

    &__icon-delete {
      color: var(--color-button-red);
      cursor: pointer;
    }

    .note-no-result {
      img {
        width: 42px;
      }

      .text-gray-700 {
        font-size: 14px;
        color: var(--color-text-grey-light);
      }

      .text-gray-900 {
        font-size: 20px;
        color: var(--color-text-primary);
      }
    }
  }

  form {
    .bg-green-note {
      align-self: flex-start;
      background-color: var(--color-button-blue);

      svg {
        height: 114px;
        color: var(--color-background-white);
      }
    }

    .bg-green-old-note {
      align-self: flex-start;
      background-color: var(--color-button-blue);

      svg {
        height: 54px;
        color: var(--color-background-white);
      }
    }
  }

  svg {
    color: var(--color-text-primary);
  }

  .border-b {
    border-bottom: none;

    h3 {
      color: var(--color-text-primary);
      text-align: center;
      width: calc(100% - 32px);
      padding-left: 32px;
      padding-top: 0;
    }

    button {
      margin-top: -1rem;
      margin-right: -1rem;

      &:hover {
        background-color: var(--color-button-blue);
        box-shadow: none;

        svg {
          transition: all 0.5s ease;
          color: var(--color-text-white);
        }
      }
    }
  }

  label {
    color: var(--color-text-primary);
    font-weight: 500;
    font-size: 14px;
  }

  .text-form {
    @include text-form;
  }
}
