.navbar {
  height: 64px;
  .container {
    margin: 0;
    max-width: inherit;
    &__dropdown {
      margin: 0px;
    }
    .text-gray-700 {
      color: var(--color-text-drop-item);
    }

    .text-gray-500 {
      color: var(--color-text-primary);
    }

    .text-gray-300 {
      color: var(--color-text-grey-light);
      font-weight: 200;
    }
  }
  &__logo {
    &--link {
      .img-desktop {
        display: block;
      }
      .img-tablet-mobile {
        display: none;
      }
    }
  }
  @media screen and (max-width: 640px) {
    &__logo {
      &--link {
        .img-desktop {
          display: none;
        }
        .img-tablet-mobile {
          display: block;
        }
      }
    }
  }
}
