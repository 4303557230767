@import './__variable.scss';
@import './__mixin.scss';

.capitalize {
  text-transform: capitalize;
}

.w-30p {
  width: 30% !important;
}

.w-2\/3 {
  width: 66.666667%;
}

.w-1\/3 {
  width: 33.333333%;
}

/*Toast*/
.Toastify__toast-icon {
  width: 24px !important;
}

.Toastify__close-button--light {
  color: #111827 !important;
  opacity: 1 !important;
  align-self: center !important;
}

.Toastify__toast {
  border-radius: 10px !important;
  padding: 0 15px !important;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.05) !important;
}

.Toastify__toast-container {
  padding: 0 !important;
}

.Toastify__toast-theme--light {
  color: #111827 !important;
  font-size: 14px !important;
}

.Toastify__toast-body {
  padding: 0 !important;
}

.break-word {
  word-break: break-word;
}

.bg-gray-50 {
  background-color: var(--color-background-sub-screen);
}

.float-right {
  float: right;
}

.header-search-group {
  max-width: 309px;
  position: relative;

  .header-search-icon {
    display: none;

    &.haveValue {
      display: block;
      color: var(--color-text-primary);

      &:hover {
        color: var(--color-button-blue);
      }
    }
  }

  .header-search-label {
    display: none !important;
    top: 10px;

    &.haveValue {
      display: block;
      background-color: var(--color-background-sub-screen);
      top: -11px;

      label {
        color: var(--color-text-grey-light);
        font-size: 12px;
      }

      &.disabled {
        background-color: var(--color-background-disabled-input);
        border-radius: 4px;

        &.haveValue {
          background-color: var(--color-background-sub-screen);
        }
      }
    }

    pointer-events: none;
    padding: 0 5px;
    left: 5px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;

    &.disabled {
      background-color: var(--color-background-disabled-input);
    }

    label {
      font-weight: normal;
      color: rgba(107, 114, 128, 1);
    }
  }

  &:focus-within {
    .header-search-label {
      display: block;
      top: -11px;
      background-color: var(--color-background-sub-screen);

      label {
        color: var(--color-text-grey-light);
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .hidden-mobile-tablet {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .hidden-mobile {
    display: none;
  }
  .w-30p {
    width: 100% !important;
  }

  .header-search-group {
    max-width: 100%;
    margin: 0 !important;

    .search-input {
      width: 100%;
    }
  }
}

body {
  &.modal-open {
    overflow: hidden;
  }

}
