@import '../../../styles/common/currencyForm.scss';
@import '../../../styles/common/multiSelect.scss';
@import '../../../styles/common/textForm.scss';
@import '../../../styles/common/datePicker.scss';

#add-partner-modal {
  color: var(--color-text-primary);

  > div {
    height: auto;
  }

  svg {
    color: var(--color-text-primary);
  }

  .border-b {
    border-bottom: none;

    h3 {
      color: var(--color-text-primary);
      text-align: center;
      width: calc(100% - 32px);
      padding-left: 32px;
      padding-top: 0;
    }

    button {
      margin-top: -1rem;
      margin-right: -1rem;

      &:hover {
        background-color: var(--color-button-blue);
        box-shadow: none;

        svg {
          transition: all 0.5s ease;
          color: var(--color-text-white);
        }
      }
    }
  }

  label {
    color: var(--color-text-primary);
    font-weight: 500;
    font-size: 14px;
  }

  .custom-multi-select {
    @include custom-multi-select;
  }

  .currency-form {
    @include currency-form;
  }

  .text-form {
    @include text-form;

    &.nickName {
      width: 49%;
    }
  }

  .date-form {
    @include date-picker;
  }
}

@media (max-width: 640px) {
  #add-partner-modal {
    flex-wrap: wrap;

    .form-flex-col {
      flex-direction: column;
      margin-top: 0;

      .custom-multi-select {
        margin: 16px 0 0 0;
      }

      .date-form {
        margin: 16px 0 0 0;
      }

      .currency-form {
        margin: 16px 0 0 0;
      }

      .text-form {
        margin: 16px 0 0 0;

        &.nickName {
          width: 100%;
        }
      }
    }
  }
}
