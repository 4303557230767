@import './mixins';

.sidebar {
  z-index: 1;
  flex-shrink: 1;
  justify-content: space-between;
  .sidebar__container {
    height: auto;
    > div {
      padding: 0;
      overflow-y: hidden;
      &.bg-gray-50 {
        background-color: white;
      }
    }
    .sidebar__container--menu {
      margin-top: 0px;
      .menu-item {
        height: 44px;
        &:hover {
          --tw-bg-opacity: 1;
          background-color: rgb(243 244 246 / var(--tw-bg-opacity));
        }

        padding: 5px 0;
        .rounded-lg {
          border-radius: 0 !important;
        }

        .p-2 {
          padding: 5px 15px;
        }

        cursor: pointer;

        a {
          background: none;
        }

        .bg-gray-100 {
          border-left: solid 3px var(--color-button-blue-sub);
          > svg {
            color: var(--color-button-blue-sub);
          }
          .text-gray-500,
          .font-semibold {
            color: var(--color-button-blue-sub);
          }
        }
      }
    }
  }
  .sidebar__footer {
    height: 72px;
    flex-direction: row;
    .hidden-mobile-tablet {
      &:focus {
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--color-button-red-focus);
      }
      background: transparent;
      color: var(--color-text-white);
      background: var(--color-button-red);
      &:hover {
        border: 1px solid var(--color-button-red);
        background: var(--color-background-white);
        color: var(--color-button-red);
      }
    }
  }

  .px-3 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .border-t {
    border-top-width: 0 !important;
  }

  .pt-4 {
    padding-top: 0 !important;
  }

  .py-4 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}

//hidden md:block
//for mobile-tablet
@media screen and (max-width: 1024px) {
  .sidebar {
    width: 60px;
    .sidebar__container {
      width: auto;
    }
    .sidebar__footer {
      flex-direction: column;
    }
    .hidden-mobile-tablet {
      display: none;
    }
    .pl-5 {
      padding-left: 10px;
    }

    .pb-5 {
      padding-bottom: 15px;
    }

    .mb-6 {
      margin-top: 8px;
      margin-bottom: 0;
    }

    aside {
      width: 100%;
    }

    ul {
      li {
        a {
          span {
            display: none;
          }
        }
      }
    }
  }
}

//for mobile
@media screen and (max-width: 640px) {
  .sidebar {
    .sidebar-logout {
      display: none;
    }
    .sidebar__container {
      height: auto;
    }
  }

  .show-dropdow-menu {
    transition: color 1s cubic-bezier(0.32, 0, 0.67, 0);
    transition: 0.5s;
    z-index: 2;
    position: fixed;
    background: white;
    width: 100%;
    height: 100%;
    padding: 10px;
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    aside {
      div {
        display: flex;
        flex-direction: column;
        height: auto;
      }
    }
    .w-64 {
      width: 100%;
    }
    .pt-4 {
      padding-top: 0;
    }
    .border-t {
      border-top-width: 0;
    }
    .mt-4 {
      margin-top: 0;
    }
    button {
      transition: color 1s cubic-bezier(0.32, 0, 0.67, 0);
      transition: 0.5s;
      align-self: end;
    }
    svg {
      color: #111928;
    }
    .sign-out {
      svg {
        color: #f05252 !important;
      }
    }
  }
}
