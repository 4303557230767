@import '../../../styles/_mixin';

.reports-page {
  .download-pdf-content {
    height: 0.1px;
    width: 0.1px;
    overflow: hidden;

  }

  &__header {
    height: 82px;
    margin: 0 !important;

    &--right {
      & > button {
        span {
          width: max-content;
        }
      }

      .container__dropdown {
        button {
          .max-w-xs {
            width: 37px;
            height: 37px;
          }

          &:hover {
            .container__dropdown--icon {
              background-color: var(--color-button-blue);
              box-shadow: none;
              transition: all 0.5s ease;
              color: var(--color-text-white);
              border-radius: 4px;
            }
          }
        }
      }
    }
  }

  &__body {
    &--tabs {
      width: 100%;

      [role='tablist'] {
        background-color: var(--color-background-white);
      }

      button {
        &:hover {
          color: var(--color-button-blue);
          border-radius: 0;
        }

        &:focus-within {
          box-shadow: none;
        }
      }



      .download-reports-button {
        margin: auto;
        margin-top: 1rem;
        background-color: var(--color-button-blue) !important;

        span {
          color: var(--color-text-white);
        }

        &:hover {
          background-color: var(--color-background-white) !important;
          border-radius: 4px;
          border: 1px solid var(--color-button-blue);

          span {
            color: var(--color-button-blue);
          }
        }

        &:focus {
          --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--color-button-blue-focus);
        }
      }

      .portfolio-report__report-buttons {
        display: flex;
        justify-content: center;
        .donwnload-button {
          margin-left: 10px;
          margin-right: 10px;

          background-color: var(--color-button-blue) !important;
          span {
            color: var(--color-text-white);
          }

          &:hover {
            background-color: var(--color-background-white) !important;
            border-radius: 4px;
            border: 1px solid var(--color-button-blue);

            span {
              color: var(--color-button-blue);
            }
          }

          &:focus {
            --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--color-button-blue-focus);
          }
        }
      }
    }
  }
}

.portfolio-report,
.summary-report {
  &__header {
    &--left {
      .header-search-group {
        .header-search-icon {
          display: none;

          &.haveValue {
            display: block;
            color: var(--color-text-primary);

            &:hover {
              color: var(--color-button-blue);
            }
          }
        }
      }
    }
  }

  &--truncate {
    text-overflow: ellipsis;

    &[data-testid='flowbite-tooltip-target'] {
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }

    &.react-tooltip {
      word-wrap: break-word;
      color: white;
    }
  }

  &__table {
    @include scrollYBem;
    max-width: calc(100vw - 270px);
    overflow-x: auto;

    .max-w-full {
      display: block;
      width: max-content;
    }

    thead {
      text-transform: capitalize;
      color: var(--color-text-primary);
      height: 46px;
    }
  }

  &__pagination {
    align-items: end;
    position: sticky;
    right: 0;
    left: 0;

    .text-gray-700 {
      color: var(--color-text-primary);
    }

    .text-gray-900 {
      color: var(--color-button-blue);
    }

    &--container {
      height: 76px;
      border-top: 1px solid rgb(209 213 219 / var(--tw-border-opacity));
    }
  }

  .card-index-group {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .group-pie-charts {
    .pie-chart-wrapper {
      .recharts-wrapper {
        width: 100% !important;
        height: fit-content !important;
        flex-direction: column;

        svg {
          position: static !important;
        }

        > svg {
          .recharts-pie {
            .recharts-layer {
              &.recharts-pie-labels {
                .recharts-layer {
                  > path {
                    display: none;
                  }
                }
              }
            }
          }
        }

        .recharts-legend-wrapper {
          position: static !important;
          top: 0 !important;
          @include scrollYBem;
          max-height: 100%;
          overflow: auto;

          > ul {
            width: max-content;
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: 15px;

            > div {
              > div {
                width: 16px;
              }

              li {
                max-width: 120px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 593px) {
  .reports-page {
    &__body {
      &--tabs {
        > button {
          width: 100%;
          justify-content: center !important;
        }


      }
    }
  }

  .portfolio-report,
  .summary-report {
    &__header {
      &--left {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }

    &__table {
      .hidden-mobile {
        display: none;
      }
    }

    .group-pie-charts {
      .pie-chart-wrapper {
        .recharts-wrapper {
          .recharts-legend-wrapper {
            max-width: 350px;

            > ul {
              > div {
                li {
                  max-width: 100px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .reports-page {
    &__header {
      height: 82px;
      flex-wrap: unset !important;
    }

    &__body {
      &--tabs {
        [role='tabpanel'] {
          &[hidden] {
            display: none;
          }
        }

        button {
          justify-content: start;
        }


      }

      .group-pie-charts {
        .pie-chart-wrapper {
          .recharts-wrapper {
            svg {
              width: 150%;
            }

            .recharts-legend-wrapper {
              > ul {
                > div {
                  li {
                    max-width: 120px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .portfolio-report,
  .summary-report {
    &__header {
      &--left {
        .header-filter-group {
          & > p {
            display: none;
          }
        }
      }
    }

    &__table {
      max-width: calc(100vw - 57px);

      .hidden-mobile {
        display: none;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reports-page {
    &__body {
      &--tabs {
        .portfolio-report,
        .summary-report {
          &__header {
            &--left {
              .header-filter-group {
                &.header-filter-group-2 {
                  & > p {
                    display: none;
                  }
                }
              }
            }
          }

          &__table {
            max-width: calc(100vw - 57px);

            .hidden-mobile-tablet {
              display: none;
            }
          }

          .group-pie-charts {
            .pie-chart-wrapper {
              .recharts-wrapper {
                svg {
                  width: 150%;
                }

                .recharts-legend-wrapper {
                  > ul {
                    > div {
                      li {
                        max-width: 140px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1025px) {
  .portfolio-report,
  .summary-report {
    &__header {
      &--left {
        .header-filter-group {
          &.header-filter-group-2 {
            & > p {
              display: none;
            }
          }
        }
      }
    }

    .card-index-group {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .group-pie-charts {
      grid-template-columns: repeat(2, minmax(0, 1fr));

      .recharts-wrapper {
        .recharts-legend-wrapper {
          position: static !important;
          top: 0 !important;
          max-width: 350px;

          > ul {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: 0.5rem;
          }
        }
      }
    }
  }
}

@media (min-width: 1280px) {
  .portfolio-report,
  .summary-report {
    .card-index-group {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .group-pie-charts {
      .recharts-wrapper {
        .recharts-legend-wrapper {
          max-width: 500px;
        }
      }
    }
  }
}

@media (min-width: 1700px) {
  .portfolio-report,
  .summary-report {
    .card-index-group {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}
