@import '../../styles/common/multiSelect.scss';
@import '../../styles/common/datePicker.scss';
@import '../../styles/common/currencyForm.scss';
@import '../../styles/common/textForm.scss';

.additional-settings {
    color: var(--color-text-primary);
    height: fit-content;
    border-radius: 8px;

    .max-w-lg {
        height: auto;
    }

    svg {
        color: var(--color-text-primary);
    }

    label {
        color: var(--color-text-primary);
        font-weight: 500;
        font-size: 14px;
    }

    .currency-form {
        @include currency-form;
    }

    .text-form {
        @include text-form;
    }
}

@media (max-width: 1024px) {
    .additional-settings {
        width: 100% !important;
    }
}

@media (max-width: 640px) {
    .additional-settings {
        padding: 20px !important;

        .form-flex-col {
            flex-direction: column;

            .currency-form {
                margin: 16px 0 0 0;
            }
        }
    }
}