@import '../../../styles/_mixin';

.supporter-page {
  display: flex;
  flex-direction: column;

  .supporter__header {
    &--right {
      & > button {
        span {
          width: max-content;
          color: var(--color-background-white);
        }

        &:hover {
          border: 1px solid var(--color-button-blue);
          background-color: var(--color-background-white);
          color: var(--color-button-blue);

          span {
            color: var(--color-button-blue);
          }
        }

        &:focus {
          --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--color-button-blue-focus);
        }

        background-color: var(--color-button-blue);
      }

      .container__dropdown {
        button {
          .max-w-xs {
            width: 37px;
            height: 37px;
          }

          &:hover {
            .container__dropdown--icon {
              background-color: var(--color-button-blue);
              box-shadow: none;
              transition: all 0.5s ease;
              color: var(--color-text-white);
              border-radius: 4px;
            }
          }
        }
      }
    }
  }

  &__container {
    height: 100%;

    .supporter-table {
      position: relative;

      &__header {
        &--search {
          .header-search-icon {
            display: none;

            &.haveValue {
              display: block;
              color: var(--color-text-primary);

              &:hover {
                color: var(--color-button-blue);
              }
            }
          }

          .header-search-label {
            display: none;
            top: 9px;

            &.haveValue {
              display: none;
            }

            pointer-events: none;
            padding: 0 5px;
            left: 5px;
            transition: 0.2s ease all;
            -moz-transition: 0.2s ease all;
            -webkit-transition: 0.2s ease all;

            &.disabled {
              background-color: var(--color-background-disabled-input);
            }

            label {
              font-weight: normal;
              color: rgba(107, 114, 128, 1);
            }
          }

          &:focus-within {
            .header-search-label {
              display: none;
              top: -11px;
              background-color: var(--color-background-table-header);

              label {
                color: var(--color-text-grey-light);
                font-size: 12px;
              }
            }
          }
        }

        &--filter {
          .header-filter-group {
            margin-left: 0.625rem;

            .custom-multi-select-input {
              &__label {
                &.haveValue {
                  display: none;
                }
              }
            }

            &:focus-within {
              .custom-multi-select-input {
                &__label {
                  top: 8px;
                  background-color: var(--color-background-white);

                  label {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }

      &__body {
        @include scrollYBem;

        table {

          &.body-without-records {
            height: 100%;
          }

          tbody {
            tr {
              height: 60px;
              cursor: pointer;

              &.supporter-last {
                border-bottom: none;
              }

              th {
                color: var(--color-text-primary);
                @media (min-width: 1025px) {
                  .lg-mr-1 {
                    margin-right: 5px;
                  }
                }
              }

              &.supporter-no-result {
                height: 100%;

                th {
                  .text-gray-700 {
                    color: var(--color-text-grey-light);
                  }

                  .text-gray-900 {
                    color: var(--color-text-primary);
                  }
                }
              }
            }
          }
        }
      }

      &.body-without-records {
        height: 100%;
      }

      &__pagination {
        align-items: end;

        .text-gray-700 {
          color: var(--color-text-primary);
        }

        .text-gray-900 {
          color: var(--color-button-blue);
        }

        &--container {
          height: 76px;
          border-top: 1px solid rgb(209 213 219 / var(--tw-border-opacity));
        }
      }

      .supporter-type {
        margin-top: 5px !important;

        .supporter-type__Donor {
          width: 47px;
          border-radius: 4px;
          padding: 4px 6px;
          background: #feecdc;
        }

        .supporter-type__Investor {
          width: auto;
          border-radius: 4px;
          background: #e5edff;
          padding: 4px 6px;
        }

        .supporter-type__Contact {
          width: auto;
          padding: 4px 6px;
          border-radius: 4px;
          background: #fdf6b2;
        }
      }
    }

    .upcomingPayments-table {
      &__header {
        .date-form {
          width: 100%;

          .react-datepicker__label {
            &.haveValue {
              display: none;
            }
          }
        }

        #next5Years {
          background-color: var(--color-text-white);
          border: 1px solid var(--color-button-blue);

          &.checked {
            background-color: var(--color-button-blue);
            border: none;
          }
        }
      }

      &__body {
        @include scrollYBem;
        height: calc(100% - 99px);

        table {
          thead {
            font-size: 14px;
            height: 68px;
          }

          tbody {
            tr {
              height: 77px;
              cursor: pointer;

              &.upcomingPayments-last {
                border-bottom: none;
              }

              th {
                color: var(--color-text-primary);

                .payment-button {
                  width: fit-content;
                }

                &.max-width-140 {
                  max-width: 140px;
                  min-width: 120px;
                }

                .upcomingPayments-page--truncate {
                  text-overflow: ellipsis;

                  &[data-testid='flowbite-tooltip-target'] {
                    overflow: hidden;
                    white-space: nowrap;
                    display: block;
                  }

                  &.react-tooltip {
                    word-wrap: break-word;
                    color: white;
                  }
                }

                @media (min-width: 1025px) {
                  .lg-mr-1 {
                    margin-right: 5px;
                  }
                  .payment-amount {
                    width: fit-content;
                  }
                }
              }

              &.upcomingPayments-no-result {
                th {
                  .text-gray-700 {
                    color: var(--color-text-grey-light);
                  }

                  .text-gray-900 {
                    color: var(--color-text-primary);
                  }
                }
              }
            }
          }
        }
      }

      .supporter-type {
        margin-left: 5px;

        .supporter-type__Donor,
        &__total {
          width: 47px;
          border-radius: 4px;
          padding: 4px 6px;
          background: #feecdc;
        }

        .supporter-type__Investor,
        &__principal {
          width: auto;
          border-radius: 4px;
          background: #fdf6b2;
          padding: 4px 6px;
        }

        .supporter-type__Contact,
        &__interest {
          width: auto;
          padding: 4px 6px;
          border-radius: 4px;
          background: #e5edff;
        }
      }

      [data-testid='no-result-content'] {
        height: calc(100% - 70px);
      }
    }
  }

  .max-width-100 {
    max-width: 100px;
    min-width: 90px;
    color: var(--color-text-primary);
  }

  &--truncate {
    text-overflow: ellipsis;

    &[data-testid='flowbite-tooltip-target'] {
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }

    &.react-tooltip {
      word-wrap: break-word;
      color: white;
    }
  }
}

@media (max-width: 1279px) {
  .supporter-page {
    table {
      height: fit-content;
    }

    &__container {
      flex-direction: column;

      .supporter-table {
        width: 100%;
        margin-left: 0 !important;

        &__body {
          max-height: 100%;
        }
      }

      .upcomingPayments-table {
        width: 100%;
        height: fit-content;

        &__body {
          max-height: 100%;
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .supporter-page {
    .supporter__header {
      &--left {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 12px;

        input {
          margin-left: 0;
          width: 65%;
        }

        .left-container {
          width: 35%;
          justify-content: end;

          span {
            display: none;
          }

          &__setting {
            padding: 0;
            margin-right: 19px;
          }

          &__menu {
            padding: 0;
            margin-right: -5px;
          }
        }
      }

      &--right {
        width: 100%;
      }
    }

    &__pagination {
      &--container {
        .hidden-mobile {
          display: none;
        }
      }
    }

    &__container {
      .upcomingPayments-table {
        &__header {
          > h4 {
            width: 100%;
            margin-bottom: 0.75rem;
          }
        }
      }

      .supporter-table {
        &__header {
          flex-direction: column;

          > h4 {
            width: 100%;
            margin-bottom: 0.75rem;
          }

          &--search {
            width: 100%;
          }

          &--filter {
            width: 100%;

            .header-filter-group {
              margin-left: 0;
              margin-top: 0.625rem;
            }
          }
        }

        table {
          tr {
            width: auto;
          }
        }
      }
    }
  }
  .max-width-80 {
    max-width: 80px;
  }
}
