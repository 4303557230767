.users-management-page {
  display: flex;
  flex-direction: column;
  .users__header {
    &--right {
      & > button {
        span {
          width: max-content;
        }
        &:hover {
          border: 1px solid var(--color-button-blue);
          background-color: var(--color-background-white);
          color: var(--color-button-blue);
        }
        &:focus {
          --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--color-button-blue-focus);
        }
        background-color: var(--color-button-blue);
      }
      .container__dropdown {
        button {
          .max-w-xs {
            width: 37px;
            height: 37px;
          }
          &:hover {
            .container__dropdown--icon {
              background-color: var(--color-button-blue);
              box-shadow: none;
              transition: all 0.5s ease;
              color: var(--color-text-white);
              border-radius: 4px;
            }
          }
        }
      }
    }
  }

  table {
    .max-width-140 {
      max-width: 140px;
      min-width: 100px;
    }
    .max-width-260 {
      max-width: 260px;
      min-width: 160px;
    }
    thead {
      text-transform: capitalize;
      color: var(--color-text-primary);
      height: 46px;
    }
    tbody {
      tr {
        height: 59px;
        &.user-last {
          border-bottom: none;
        }
        th {
          color: var(--color-text-primary);
          @media (min-width: 1025px) {
            .lg-mr-1 {
              margin-right: 5px;
            }
          }
        }
        &.user-no-result {
          th {
            .text-gray-700 {
              color: var(--color-text-grey-light);
            }
            .text-gray-900 {
              color: var(--color-text-primary);
            }
          }
        }
      }
    }
  }

  &--truncate {
    text-overflow: ellipsis;
    &[data-testid='flowbite-tooltip-target'] {
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }
    &.react-tooltip {
      word-wrap: break-word;
      color: white;
    }
  }

  &__pagination {
    align-items: end;
    height: 100%;
    .text-gray-700 {
      color: var(--color-text-primary);
    }

    .text-gray-900 {
      color: var(--color-button-blue);
    }

    &--container {
      height: 74px;
      border-top: 1px solid rgb(209 213 219 / var(--tw-border-opacity));
    }
  }
}

@media (max-width: 640px) {
  .users-management-page {
    .users__header {
      flex-direction: column;
      &--left {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 12px;
        .left-container {
          width: 35%;
          justify-content: end;
          &__setting {
            padding: 0;
            margin-right: 19px;
          }
          &__menu {
            padding: 0;
            margin-right: -5px;
          }
        }
      }
      &--right {
        width: 100%;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        & > button {
          width: 100%;
        }
      }
    }
    &__pagination {
      &--container {
        .hidden-mobile {
          display: none;
        }
      }
    }
  }
}
