@import '../../../styles/_mixin';

.date-form {
  .react-datepicker-wrapper {
    width: 100%;

    &:disabled {
      background-color: var(--color-background-disabled-input);
    }

    .haveValue {
    }

    .react-datepicker__input-container {
      width: 100%;

      .react-datepicker__schedule-icon {
        &.haveValue {
          display: none;
        }

        transition: all .5s ease;

        &:hover {
          background-color: var(--color-button-blue);
          color: var(--color-text-white);
          box-shadow: none;
          border-radius: 4px;
        }
      }

      .react-datepicker__close-icon {
        right: 4px;

        &::after {
          background-color: var(--color-background-white);
          color: var(--color-text-primary);
          font-size: 20px;
        }

        &:hover {
          &::after {
            color: var(--color-button-blue);
          }
        }

        &.clear-button-disabled {
          display: none;
        }
      }

      input {
        width: 100%;

        &:disabled {
          background-color: var(--color-background-disabled-input);
        }
      }

      .react-datepicker__label {
        top: 8px;
        display: none !important;

        &.haveValue {
          background-color: var(--color-background-sub-screen);
          display: block;
          top: -11px;

          label {
            color: var(--color-text-grey-light);
            font-size: 12px;
          }

          &.disabled {
            background-color: var(--color-background-disabled-input);
            border-radius: 4px;

            &.haveValue {
              background-color: var(--color-background-sub-screen);
            }
          }
        }

        pointer-events: none;
        padding: 0 5px;
        left: 5px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;

        &.disabled {
          background-color: var(--color-background-disabled-input);
        }

        label {
          font-weight: normal;
          color: rgba(107, 114, 128, 1);
        }
      }
    }

    .pr-20 {
      padding-right: 1.375rem;
      padding-left: 10px;
    }

    &:focus-within {
      .react-datepicker__label {
        display: block;
        top: -11px;
        background-color: var(--color-background-sub-screen);

        label {
          color: var(--color-text-grey-light);
          font-size: 12px;
        }
      }
    }
  }

  .react-datepicker__tab-loop {

    [aria-current='date'] {
      color: var(--color-background-white);
      background-color: var(--color-button-blue);
      border-radius: 4px;
    }

    [aria-selected='true'] {
      color: var(--color-button-blue);
      background-color: var(--color-background-white);
      border-radius: 0;
      border-bottom: 1px solid var(--color-button-blue);
    }

    .react-datepicker__navigation-icon {
      &--previous, &--next {
        top: 4px
      }
    }

    .react-datepicker__header__dropdown--scroll {
      margin-top: 5px;

      .react-datepicker__year-dropdown--scrollable {
        @include scrollYBem;

        a {
          display: none;
        }
      }
    }

    .react-datepicker__navigation {
      height: 36px;
    }
  }
}
