@import '../../../styles/_mixin';

.text-input {
  &__label {
    top: 8px;
    &.haveValue {
      background-color: var(--color-background-white);
      display: block;
      top: -11px;
      label {
        color: var(--color-text-grey-light);
        font-size: 12px;
      }
      &.disabled {
        background-color: var(--color-background-disabled-input);
        border-radius: 4px;
        &.haveValue {
          background-color: var(--color-background-white);
        }
      }
    }
    pointer-events: none;
    padding: 0 5px;
    left: 5px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    &.disabled {
      background-color: var(--color-background-disabled-input);
    }
    label {
      font-weight: normal;
      color: rgba(107, 114, 128, 1);
    }
  }

  &:focus-within {
    .text-input__label {
      display: block;
      top: -11px;
      background-color: var(--color-background-white);
      label {
        color: var(--color-text-grey-light);
        font-size: 12px;
      }
    }
  }
}
