@import '../../../styles/common/currencyForm.scss';
@import '../../../styles/common/multiSelect.scss';
@import '../../../styles/common/datePicker.scss';
@import '../../../styles/common/textForm.scss';

#add-schedule-modal {
  color: var(--color-text-primary);
  height: 100%;
  > div {
    height: auto;
  }
  svg {
    color: var(--color-text-primary);
  }
  .border-b {
    border-bottom: none;
    h3 {
      color: var(--color-text-primary);
      text-align: center;
      width: calc(100% - 32px);
      padding-left: 32px;
      padding-top: 0;
    }
    button {
      margin-top: -1rem;
      margin-right: -1rem;
      &:hover {
        background-color: var(--color-button-blue);
        box-shadow: none;
        svg {
          transition: all 0.5s ease;
          color: var(--color-text-white);
        }
      }
    }
  }

  label {
    color: var(--color-text-primary);
    font-weight: 500;
    font-size: 14px;
  }

  .custom-multi-select {
    @include custom-multi-select;
  }
  .date-form {
    @include date-picker;
  }
  .currency-form {
    @include currency-form;
  }
  .text-form {
    @include text-form;
  }
}
