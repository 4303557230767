.summary-report-pdf {
  &.summary-report__table {
    max-width: 100% !important;

    table {
      max-width: 100% !important;

      th {
        padding: 16px 5px;
        margin: 0;
        height: auto;
        width: auto;
        font-weight: 600;

        &:first-child {
          padding-left: 10px;
        }

        &:last-child {
          padding-right: 10px;
        }

        .max-w-full {
          width: unset;
          white-space: unset;
          overflow: unset;
          &[data-tooltip-id="summary-report-date-0"] {
            width: max-content;
          }
        }
      }
    }
  }
}
