@import '../../../styles/common/currencyForm.scss';
@import '../../../styles/common/multiSelect.scss';
@import '../../../styles/common/textForm.scss';
@import '../../../styles/common/datePicker.scss';
@import '../../../styles/common/checkboxForm.scss';

#add-supporter-modal {
  height: 100%;
  color: var(--color-text-primary);
  .pl-5 {
    padding-left: 5px;
  }
  > div {
    height: auto;
  }
  svg {
    color: var(--color-text-primary);
  }
  .border-b {
    border-bottom: none;
    h3 {
      color: var(--color-text-primary);
      text-align: center;
      width: calc(100% - 32px);
      padding-left: 32px;
      padding-top: 0;
    }
    button {
      margin-top: -1rem;
      margin-right: -1rem;
      &:hover {
        background-color: var(--color-button-blue);
        box-shadow: none;
        svg {
          transition: all 0.5s ease;
          color: var(--color-text-white);
        }
      }
    }
  }

  label {
    color: var(--color-text-primary);
    font-weight: 500;
    font-size: 14px;
  }

  .custom-multi-select {
    @include custom-multi-select;
  }
  .currency-form {
    @include currency-form;
  }
  .text-form {
    @include text-form;
  }
  .date-form {
    @include date-picker;
  }
  .checkbox-form {
    @include checkbox-form;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  }
  .row-highlight {
    background: #FDFDEA;
    padding: calc(1rem*(1 - var(--tw-space-y-reverse)));
    &-old {
      margin-bottom: 0;
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-box-shadow: 0 0 0px 1000px #FDFDEA inset;
      }
    }
    &-even {
      margin-top: 0;
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-box-shadow: 0 0 0px 1000px #FDFDEA inset;
      }
    }

    &-grey {
      background-color: rgb(249 250 251 / var(--tw-bg-opacity));
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-box-shadow: 0 0 0px 1000px rgb(249 250 251 / var(--tw-bg-opacity)) inset;
      }
    }
    input:not([type='checkbox']) {
      background: transparent;
    }

    input:-webkit-autofill {
        background: transparent !important;

    }

    .custom-multi-select .multi-select .dropdown-container {
      background: transparent;
    }
    .bg-white {
      background: transparent;
    }
  }
}

@media (max-width: 640px) {
  #add-supporter-modal {
    flex-wrap: wrap;
    height: 100%;
    .form-flex-col {
      flex-direction: column;
      margin: unset;
      &.form-inserted {
        width: 100%;
        .text-form {
          margin-left: 0;
          margin-bottom: 0.625rem;
        }
      }
    }
    .form-input {
      width: 100%;
      padding: unset;
      margin: unset;
      margin-bottom: 10px;
    }
    .row-highlight {
      &-old {
        padding-bottom: 0;
        margin: 0;
      }
      &-even {
        padding-top: 0;
        margin-top: 0;
        margin-bottom: calc(1rem*(1 - var(--tw-space-y-reverse)));
      }
    }
  }
}
