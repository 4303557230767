@mixin scrollY {
  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--color-background-scroll-bar);
    border-radius: 4px;
  }
}

@mixin scrollYBem {
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--color-background-scroll-bar);
    border-radius: 4px;
  }
}

@mixin truncate-text($lines-to-show: 3) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines-to-show;
  text-overflow: ellipsis;
}