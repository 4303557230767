@mixin checkbox-form {
  input {
    width: 20px;
    height: 20px;
    background-color: var(--color-background-white);
    border: 1px solid var(--color-button-blue);
    &:checked {
      background-color: var(--color-button-blue);
    }
    &:disabled {
      background-color: var(--color-background-disabled-input);
    }
  }
}
