@import '../../../../styles/__mixin';

.partner-detail-page {
  flex: 1;

  .partner-detail {
    width: 100%;

    &__left {
      width: 33.33%;

      &--info {
        background-color: var(--color-background-white);

        .partner-info__container {
          > div > h3 {
            overflow-wrap: break-word;
          }

          .contact {
            .button {
              display: none;

              .edit-button {
                width: 26px;
                height: 26px;
                // background: white;

                position: absolute;
                top: 50px;
                right: 150px;
              }
              .delete-button {
                width: 26px;
                height: 26px;
                background: white;
                position: absolute;
                top: 50px;
                right: 120px;
              }
              .delete-button:hover {
                background: #F05252;
              }
            }
          }
          .showhover:hover {
            background: #1119284d;
            .button {
              display: inline-block;
            }
          }
        }
      }

      &--note {
        .note__container {
          height: 100%;

          &--title {
            button {

            }
          }

          &--content {
            .content-text {
              &[data-testid='flowbite-tooltip-target'] {
                @include truncate-text;
              }

              &.react-tooltip {
                width: fit-content;
                word-wrap: break-word;
                color: white;
              }
            }

            .note-no-result {
              img {
                width: 42px;
              }

              .text-gray-700 {
                font-size: 14px;
                color: var(--color-text-grey-light);
              }

              .text-gray-900 {
                font-size: 20px;
                color: var(--color-text-primary);
              }
            }
          }
        }
      }
    }

    &__right {
      width: 66.66%;
      flex: 1;
      display: flex;
      flex-direction: column;

      .loan {
        flex: 1;
        display: flex;
        flex-direction: column;

        &__header {
          button {
            height: auto;
          }
        }

        table {
          .max-width-80 {
            max-width: 80px;
            min-width: 60px;
          }

          .max-width-40 {
            max-width: 40px;
            min-width: 30px;
          }

          .max-width-120 {
            max-width: 120px;
            min-width: 100px;
          }

          thead {
            text-transform: capitalize;
            color: var(--color-text-primary);
            height: 46px;
          }

          tbody {
            tr {
              height: 53px;

              &.loan-last {
                border-bottom: none;
              }

              th {
                color: var(--color-text-primary);

                button {
                  max-height: 28px;
                  object-fit: cover;
                  max-width: 28px;
                  padding-left: 0.29rem !important;
                  padding-right: 0.26rem !important;
                }
              }

              &.loan-no-result {
                th {
                  img {
                    width: 51px;
                  }

                  .text-gray-700 {
                    font-size: 16px;
                    color: var(--color-text-grey-light);
                  }

                  .text-gray-900 {
                    font-size: 26px;
                    color: var(--color-text-primary);
                  }
                }
              }
            }
          }

          .loans--truncate {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        &__pagination {
          flex: 1;
          background-color: var(--color-text-white);
          align-items: end;

          .text-gray-700 {
            color: var(--color-text-primary);
            font-size: small;
          }

          .text-gray-900 {
            color: var(--color-button-blue);
            font-size: small;
          }

          &--container {
            height: 60px;
            border-top: 1px solid rgb(209 213 219 / var(--tw-border-opacity));
          }
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .partner-detail-page {
    height: 100%;

    .header {
      height: 73px;
    }

    .partner-detail {
      flex-direction: column;

      &__left {
        width: 100%;

        &--info {
          margin-right: 0;
          max-height: 100%;
        }

        &--note {
          width: 100%;

          .note__container {
            &--content {
              height: 100%;
              max-height: 100%;
              align-items: center;
              justify-content: center;
              overflow-y: unset;

              .note-no-result {
                margin: 1rem 0;
              }
            }
          }
        }
      }

      &__right {
        width: 100%;
        margin: 1rem !important;
        margin-bottom: 0 !important;

        .card-index-group {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        .loan {
          &__header {
            padding-top: 0;
          }

          &__pagination {
            &--container {
              .hidden-mobile {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 641px) and (max-width: 820px) {
  .partner-detail-page {
    .partner-detail {
      flex-direction: column;

      &__left {
        width: 100%;
        display: flex;
        flex-direction: row;

        &--info {
          width: 300px;
          height: inherit;
          margin-right: 1rem;
        }

        &--note {
          width: calc(100% - 300px);
          margin-top: 0 !important;

          .note__container {
            height: 100%;
          }
        }
      }

      &__right {
        width: 100%;
        margin: 1rem 1rem 0 1rem !important;

        .card-index-group {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        .loan {
          &__header {
            padding-top: 0;
          }
        }
      }
    }
  }
}

@media (min-width: 821px) and (max-width: 1199px) {
  .partner-detail-page {
    .partner-detail {
      flex-direction: column;

      &__left {
        width: 100%;
        display: flex;
        flex-direction: row;

        &--info {
          width: 300px;
          height: inherit;
          margin-right: 1rem;
        }

        &--note {
          width: calc(100% - 300px);
          margin-top: 0 !important;

          .note__container {
            height: 100%;
          }
        }
      }

      &__right {
        width: 100%;
        margin: 1rem 1rem 0 1rem !important;

        .card-index-group {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        .loan {
          &__header {
            padding-top: 0;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .partner-detail-page {
    .partner-detail {
      &__left {
        flex: 1;
        max-width: 300px;
        display: flex;
        flex-direction: column;
        align-self: center;

        &--note {
          flex: 1;
        }
      }

      &__right {
        width: calc(100% - 300px);
        flex: 1;
        display: flex;
        flex-direction: column;

        .card-index-group {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
      }
    }
  }
}
