@import '../../../../styles/_mixin';

.loan-detail-page {
  background-color: var(--color-background-sub-screen);

  .loan-detail {
    &__top {
      .card-index-group {
        .bg-teal-100 {
          background-color: #ccfbf1;
        }

        .bg-teal-500 {
          background-color: #06b6d4;
        }

        .card-index__container {
          flex: 1;
        }
      }
    }

    &__bottom {
      .payment {
        &__header {
          &--tabs {
            width: 100%;
            border: none;
            gap: 0;
            flex: 1;

            [role='tablist'] {
              max-height: 41px;
              display: none;
            }

            &.gap-2 {
              height: 100%;
            }

            @include scrollY;

            &>div+div {
              overflow: auto;
              height: 100%;

              [role='tabpanel'] {
                &[hidden] {
                  display: none;
                }
              }

              [role='tabpanel'] {
                padding: 0;
                height: 100%;
                display: flex;
                flex-direction: column;

                &[hidden] {
                  display: none;
                }

                table {
                  button {
                    height: auto;
                    width: auto;
                  }

                  .max-width-80 {
                    max-width: 80px;
                    min-width: 60px;
                  }

                  .max-width-108 {
                    max-width: 108px;
                    min-width: 90px;
                  }

                  thead {
                    position: sticky;
                    z-index: 1;
                    top: 0;
                    text-transform: capitalize;
                    color: var(--color-text-primary);
                    height: 46px;
                  }

                  tbody {
                    tr {
                      height: 54px;

                      &.payment-last {
                        border-bottom: none;
                      }

                      th {
                        color: var(--color-text-primary);

                        button {
                          padding: 0.25rem;
                        }

                        .date-form,
                        .currency-input,
                        .multi-select {
                          max-width: 250px;
                          font-size: 0.75rem;

                          input {
                            font-size: 0.75rem;
                          }
                        }
                      }

                      &.payment-no-result {
                        th {
                          img {
                            width: 51px;
                          }

                          .text-gray-700 {
                            font-size: 16px;
                            color: var(--color-text-grey-light);
                          }

                          .text-gray-900 {
                            font-size: 26px;
                            color: var(--color-text-primary);
                          }
                        }
                      }
                    }
                  }

                  tfoot {
                    text-transform: capitalize;
                    color: var(--color-text-primary);
                    height: 46px;
                  }

                  .payment--truncate {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &.max-width-80 {
                      max-width: 80px;
                      min-width: 60px;
                    }
                  }
                }

                .payment__pagination {
                  flex: 1;
                  background-color: var(--color-text-white);
                  align-items: end;

                  .text-gray-700 {
                    color: var(--color-text-primary);
                    font-size: small;
                  }

                  .text-gray-900 {
                    color: var(--color-button-blue);
                    font-size: small;
                  }

                  &--container {
                    height: 60px;
                    border-top: 1px solid rgb(209 213 219 / var(--tw-border-opacity));
                    border-bottom: none;
                  }
                }
              }
            }

            [role='tablist'] {
              button {
                padding-top: 20px;
                padding-bottom: 20px;
                border-radius: 0;

                &:hover {
                  border: none;
                }

                &.bg-gray-100 {
                  background-color: var(--color-text-white);
                }
              }
            }
          }

          &--paymentSchedule {
            display: flex;
            flex-direction: column;

            .button-group {
              button {
                height: 26px;
              }
            }
          }
        }
      }
    }

    &__notes {
      .note__container {
        height: 100%;

        &--content {

          .content-item {
            position: relative;
            border: 1px solid #fbe96a;

            &.border-left-none {
              border-left: none;
            }

            &.border-top-none {
              border-top: none;
            }

            .content-text {
              &[data-testid='flowbite-tooltip-target'] {
                @include truncate-text
              }

              &.react-tooltip {
                max-width: 100%;
                word-wrap: break-word;
                color: white;
              }
            }
          }

          .note-no-result {
            img {
              width: 42px;
            }

            .text-gray-700 {
              font-size: 14px;
              color: var(--color-text-grey-light);
            }

            .text-gray-900 {
              font-size: 20px;
              color: var(--color-text-primary);
            }
          }
        }
      }
    }
  }

  .download-pdf-content {
    height: 0.1px;
    overflow: hidden;
  }
}

@media (max-width: 640px) {
  .loan-detail-page {
    .loan-detail {
      &__top {
        .card-index-group {
          grid-template-columns: repeat(1, minmax(0, 1fr));

          .bg-teal-100 {
            background-color: #ccfbf1;
          }

          .bg-teal-500 {
            background-color: #06b6d4;
          }
        }
      }

      &__middle {
        .note__container {
          height: 100%;

          &--content {
            grid-template-columns: repeat(1, 1fr);

            .content-item {

              &.border-left-none {
                border-left: 2px solid #fbe96a;
                border-top: none
              }

              &.border-top-none {}
            }
          }
        }
      }

      &__bottom {
        .payment {
          &__header {

            &--tabs,
            .have-collateral {
              &>div+div {
                height: 100%;
              }
            }

            .date-form {
              max-width: 100% !important;
            }
          }

          &__pagination {
            &--container {
              .hidden-mobile {
                display: none;
              }
            }
          }
        }
      }

    }
  }
}

@media (min-width: 641px) and (max-width: 820px) {
  .loan-detail-page {
    .loan-detail {
      &__top {
        .card-index-group {
          grid-template-columns: repeat(3, minmax(0, 1fr));

          .bg-teal-100 {
            background-color: #ccfbf1;
          }

          .bg-teal-500 {
            background-color: #06b6d4;
          }

          .card-index__container {
            flex: 1;
          }
        }
      }

      &__bottom {
        .payment {
          &__header {
            padding-top: 0;

            &--tabs,
            .have-collateral {
              &>div+div {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 821px) and (max-width: 1280px) {
  .loan-detail-page {
    .loan-detail {
      &__top {
        .card-index-group {
          grid-template-columns: repeat(4, minmax(0, 1fr));

          .bg-teal-100 {
            background-color: #ccfbf1;
          }

          .bg-teal-500 {
            background-color: #06b6d4;
          }

          .card-index__container {
            flex: 1;
          }
        }
      }

      &__bottom {
        .payment {
          &__header {
            padding-top: 0;

            &--tabs,
            .have-collateral {
              &>div+div {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1281px) {
  .loan-detail-page {
    .loan-detail {
      &__top {
        .card-index-group {
          .bg-teal-100 {
            background-color: #ccfbf1;
          }

          .bg-teal-500 {
            background-color: #06b6d4;
          }
        }
      }

      &__bottom {
        .payment {
          &__header {
            padding-top: 0;
          }
        }
      }
    }
  }
}
