@import '../../../styles/_mixin';

.custom-multi-select-input {
  .rmsc {
    --rmsc-h: 36px;
    --rmsc-border: var(--color-button-gray-focus);

    &.filter-group {
      .dropdown-content {
        cursor: pointer;
        top: 41px !important;
        width: 100% !important;

        .options {
          @include scrollYBem;
          max-height: 240px;
        }
      }
    }

    .dropdown-container {
      //position: unset;

      .dropdown-heading {
        cursor: pointer;
        position: unset;

        .dropdown-heading-dropdown-arrow {
          &:hover {
            background-color: var(--color-button-blue);
            color: var(--color-text-white);
            box-shadow: none;
            border-radius: 4px;
          }
        }

        .clear-selected-button {
          pointer-events: none;

          svg {
            display: none;
          }
        }
      }
    }
  }

  &__label {
    top: 8px;
    display: none !important;

    &.haveValue {
      background-color: var(--color-background-sub-screen);
      display: block;
      top: -11px;

      label {
        color: var(--color-text-grey-light);
        font-size: 12px;
      }

      &.disabled {
        background-color: var(--color-background-disabled-input);
        border-radius: 4px;

        &.haveValue {
          background-color: var(--color-background-sub-screen);
        }
      }
    }

    pointer-events: none;
    padding: 0 5px;
    left: 5px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;

    &.disabled {
      background-color: var(--color-background-disabled-input);
    }

    label {
      font-weight: normal;
      color: rgba(107, 114, 128, 1);
    }
  }

  &:focus-within {
    .custom-multi-select-input__label {
      display: block;
      top: -11px;
      background-color: var(--color-background-sub-screen);

      label {
        color: var(--color-text-grey-light);
        font-size: 12px;
      }
    }
  }
}
