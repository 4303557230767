$black: #111928 !default;
$all-black: #000000;
$white: #ffff;
$sub-white: #e3e3e3;
$bg-dark: #1e2733;
$bg-comment: #f4f4f4 !default;
$bg-comment-dark: #071021;
$bg-sub-screen: #f7f9fa;
$border: #e4e4e4;
$dark-border: #656565;
$grey: #707070;
$grey-light: #6b7280;
$button-primary: #a1c93a;
$button-primary-2: #55853b;
$button-primary-focus: #c5dd80;
$button-secondary: #f05252;
$button-secondary-focus: #fc7474;
$button-gray: rgb(156 163 175);
$button-gray-focus: rgb(229 231 235);
$bg-disabled-input: rgba(246, 248, 241, 0.5);
$bg-scroll-bar: rgba(65, 54, 66, 0.6);
$bg-table-header: #e5e7eb;

:root {
  --color-text-primary: #{$black};
  --color-text-drop-item: #6b7280;
  --color-text-grey-light: #{$grey-light};
  --color-text-white: #{$white};
  --color-background-sub-screen: #{$bg-sub-screen};
  --color-background-white: #{$white};
  --color-text-red-hover: #8e0f0f;
  --color-button-blue: #{$button-primary};
  --color-button-blue-sub: #{$button-primary-2};
  --color-button-blue-focus: #{$button-primary-focus};
  --color-button-red: #{$button-secondary};
  --color-button-red-focus: #{$button-secondary-focus};
  --color-button-gray: #{$button-gray};
  --color-button-gray-focus: #{$button-gray-focus};
  --color-background-disabled-input: #{$bg-disabled-input};
  --color-background-scroll-bar: #{$bg-scroll-bar};
  --color-background-table-header: #{$bg-table-header};
}
