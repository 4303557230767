@import '../../../styles/__mixin';

.payments-page {

  &__body {
    &--tabs {
      width: 100%;

      [role='tablist'] {
        background-color: var(--color-background-white);
      }

      [role='tabpanel'] {
        padding: 0;
      }

      button {
        &:focus-within {
          box-shadow: none;
        }

        &.add-loan-payment-button {
          height: auto !important;
        }
      }

      .loan-payments, .loan-paymentsSchedule {
        display: flex;
        flex-direction: column;

        &__header {
          &--right {
            & > button {
              span {
                width: max-content;
              }

              &:hover {
                border: 1px solid var(--color-button-blue);
                background-color: var(--color-background-white);
                color: var(--color-button-blue);
              }

              &:focus {
                --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--color-button-blue-focus);
              }

              background-color: var(--color-button-blue);
            }

            .container__dropdown {
              button {
                .max-w-xs {
                  width: 37px;
                  height: 37px;
                }

                &:hover {
                  .container__dropdown--icon {
                    background-color: var(--color-button-blue);
                    box-shadow: none;
                    transition: all 0.5s ease;
                    color: var(--color-text-white);
                    border-radius: 4px;
                  }
                }
              }
            }
          }

          .header-filter-group {
            .date-form {
              max-width: 309px;
            }

            .custom-multi-select-input {
              max-width: 309px;
            }
          }
        }

        &__table {
          max-width: calc(100vw - 256px);
          overflow-x: auto;
          @include scrollYBem;

          table {
            .date-form {
              min-width: 180px;
            }

            .currency-input {
              min-width: 150px;
            }

            .custom-multi-select-input {
              min-width: 180px;
            }

            thead {
              text-transform: capitalize;
              color: var(--color-text-primary);
              height: 46px;
            }

            tbody {
              tr {
                height: 62px;

                &.payment-last {
                  border-bottom: none;
                }

                th {
                  color: var(--color-text-primary);
                  @media (min-width: 1025px) {
                    .lg-mr-1 {
                      margin-right: 5px;
                    }
                  }
                }

                &.payment-no-result {
                  th {
                    .text-gray-700 {
                      color: var(--color-text-grey-light);
                    }

                    .text-gray-900 {
                      color: var(--color-text-primary);
                    }
                  }
                }
              }
            }
          }
        }

        &--truncate {
          text-overflow: ellipsis;

          &[data-testid='flowbite-tooltip-target'] {
            overflow: hidden;
            white-space: nowrap;
            display: block;
          }

          &.react-tooltip {
            word-wrap: break-word;
            color: white;
          }
        }

        &__pagination {
          flex: 1;
          align-items: end;

          .text-gray-700 {
            color: var(--color-text-primary);
          }

          .text-gray-900 {
            color: var(--color-button-blue);
          }

          &--container {
            height: 76px;
            border-top: 1px solid rgb(209 213 219 / var(--tw-border-opacity));
          }
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .payments-page {
    .loan-payments {
      &__table {
        max-width: calc(100vw - 80px);
      }

      .payments__header {
        flex-direction: column;

        &--left {
          width: 100% !important;
          gap: 15px;
          margin-bottom: 10px;
          flex-direction: column;

          .bulkhead {
            display: none;
          }

          input {
            margin-left: 0;
          }

          .left-container {
            width: 35%;
            justify-content: end;

            span {
              display: none;
            }

            &__setting {
              padding: 0;
              margin-right: 19px;
            }

            &__menu {
              padding: 0;
              margin-right: -5px;
            }
          }

          .header-search-group {
            width: 100%;
            max-width: 100%;
          }

          .header-filter-title {
            width: 100%;
          }

          .header-filter-group {
            flex-direction: column;
            margin: 0;
            width: 100%;

            .date-form {
              max-width: 100%;
            }

            .custom-multi-select-input {
              max-width: 100%;
            }
          }
        }

        &--right {
          width: 100%;
          justify-content: flex-end;
          margin: 0;
          padding: 0;

          .container__dropdown {
            margin-right: 0;
          }

          & > button {
            width: 100%;
          }
        }
      }

      table {
        thead {
          height: 44px;
        }
      }

      &__pagination {
        &--container {
          .hidden-mobile {
            display: none;
          }
        }
      }
    }
  }
}

@media (min-width: 641px) and (max-width: 1024px) {
  .payments-page {
    .loan-payments {
      &__table {
        max-width: calc(100vw - 56px);
      }
    }
  }
}

@media (min-width: 641px) and (max-width: 1280px) {
  .payments-page {
    .loan-payments {
      .payments__header {
        &--left {
          .header-search-group {
            max-width: 200px;
          }
        }

        &--right {
          .container__dropdown {
            margin-right: 1rem;
          }

          > button {
            svg {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
