@import '../_mixin';

@mixin custom-multi-select {
  &.disabled {
    background-color: var(--color-background-disabled-input);
    pointer-events: none;
  }

  &__label {
    top: 0.9rem;
    &.haveValue {
      display: block;
      top: -13px;
      left: 10px;
      label {
        color: var(--color-text-grey-light);
        font-size: 12px;
      }
      &.disabled {
        background-color: var(--color-background-disabled-input);
        border-radius: 4px;
        &.haveValue {
          background-color: var(--color-background-white);
        }
      }
    }
    pointer-events: none;
    left: 10px;
    padding: 0 5px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    &.disabled {
      background-color: var(--color-background-disabled-input);
    }
    label {
      font-weight: normal;
      color: rgba(107, 114, 128, 1);
    }
  }

  &:focus-within {
    .custom-multi-select__label {
      display: block;
      top: -13px;
      label {
        color: var(--color-text-grey-light);
        font-size: 12px;
      }
    }
    .dropdown-heading-value__no-content {
      display: none;
    }
  }

  .multi-select {
    &.rmsc {
      --rmsc-h: none;
      --rmsc-border: #e5e7eb;
    }
    &.disabled {
      .dropdown-container {
        background-color: var(--color-background-disabled-input);
        .dropdown-heading-value__no-content {
          background-color: var(--color-background-disabled-input);
        }
      }
    }
    &.error {
      .dropdown-container {
        border-color: red;
        box-shadow: none;
        &:focus-within {
          box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
          --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) red;
        }
      }
    }
    &.single-select {
      .dropdown-content {
        .options {
          .select-item {
            .item-renderer {
              input {
                display: none;
              }
            }
          }
        }
      }
    }
    .dropdown-container {
      display: flex;
      align-items: center;
      border-width: 1px;
      border-radius: 4px;
      font-size: 0.875rem;
      min-height: 54px;
      line-height: 1.25rem;
      background-color: var(--color-background-white);
      .dropdown-heading {
        padding-top: 0.9rem;
        padding-bottom: 0.9rem;
        .dropdown-heading-value {
          @include scrollY;
          &__no-content {
            display: none;
          }
          &__content {
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 78px;
            &--item {
              float: left;
              max-width: auto;
              word-wrap: break-word;
              white-space: initial;
              color: var(--color-text-primary);
              padding: 4px;
              border-radius: 4px;
              span {
                font-size: 14px;
                line-height: 14px;
              }
              button {
                padding: 1px;
                border-radius: 4px;
                &:hover {
                  background-color: var(--color-button-blue);
                  box-shadow: none;
                  svg {
                    color: #e5e7eb;
                  }
                }
              }
            }
          }
          &__hiX {
            min-width: 24px;
            min-height: 24px;
            padding: 3px;
            border-radius: 4px;
            margin-right: 2px;
            &:hover {
              background-color: var(--color-button-blue);
              box-shadow: none;
              svg {
                color: #e5e7eb;
              }
            }
          }
        }
        .clear-selected-button {
          svg {
            display: none;
          }
        }
        .open-menu-icon {
          width: 24px;
          height: 22px;
          border-radius: 4px;
          transition: all 0.5s ease;
          cursor: pointer;
          &:hover {
            background-color: var(--color-button-blue);
            color: var(--color-text-white);
            box-shadow: none;
          }
        }
      }
    }
    .dropdown-content {
      display: inline !important;
      width: auto;
      left: -1px;
      right: -1px;
      .panel-content {
        border-radius: 4px;
        .select-panel {
          .search {
            --rmsc-h: 38px;
            ::placeholder {
              color: var(--color-text-primary);
            }
            color: var(--color-text-primary);
            font-size: 14px;
            font-weight: 400;
            &:focus-within {
              input {
                background-color: #f9fafb;
                box-shadow: none;
              }
            }
            .search-clear-button {
              padding: auto;
              margin: auto;
              margin-right: 4px;
              transition: all 0.2s ease;
              &:hover {
                svg {
                  background-color: var(--color-button-blue);
                  color: var(--color-text-white);
                  border-radius: 4px;
                  box-shadow: none;
                }
              }
            }
          }
          .options {
            @include scrollYBem;
            max-height: 240px;
            .select-item {
              font-weight: 400;
              &:hover {
                background-color: #eef6f6;
              }
              &:active {
                background-color: none;
              }
              &.selected {
                background-color: #eef6f6;
              }
              .item-renderer {
                color: var(--color-text-primary);
                align-items: center;
                input {
                  border-radius: 4px;
                  &:focus {
                    box-shadow: none;
                  }
                  &:checked {
                    background-color: var(--color-button-blue);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
