@import '../../../styles/__mixin';

.partners-page {
  display: flex;
  flex-direction: column;

  .partners__header {
    &--right {
      & > button {
        span {
          width: max-content;
          color: var(--color-background-white);
        }

        &:hover {
          border: 1px solid var(--color-button-blue);
          background-color: var(--color-background-white);
          color: var(--color-button-blue);

          span {
            color: var(--color-button-blue);
          }
        }

        &:focus {
          --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--color-button-blue-focus);
        }

        background-color: var(--color-button-blue);
      }

      .container__dropdown {
        button {
          .max-w-xs {
            width: 37px;
            height: 37px;
          }

          &:hover {
            .container__dropdown--icon {
              background-color: var(--color-button-blue);
              box-shadow: none;
              transition: all 0.5s ease;
              color: var(--color-text-white);
              border-radius: 4px;
            }
          }
        }
      }
    }
  }

  table {
    .max-width-140 {
      max-width: 140px;
      min-width: 100px;
    }

    .max-width-260 {
      max-width: 260px;
      min-width: 160px;
    }

    thead {
      text-transform: capitalize;
      color: var(--color-text-primary);
      height: 46px;
    }

    tbody {
      tr {
        height: 59px;
        cursor: pointer;

        &.partner-last {
          border-bottom: none;
        }

        th {
          color: var(--color-text-primary);
          @media (min-width: 1025px) {
            .lg-mr-1 {
              margin-right: 5px;
            }
          }
        }

        &.partner-no-result {
          th {
            .text-gray-700 {
              color: var(--color-text-grey-light);
            }

            .text-gray-900 {
              color: var(--color-text-primary);
            }
          }
        }
      }
    }
  }

  &--truncate {
    text-overflow: ellipsis;

    &[data-testid='flowbite-tooltip-target'] {
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }

    &.react-tooltip {
      word-wrap: break-word;
      color: white;
    }
  }

  &__pagination {
    height: 100%;
    align-items: end;

    .text-gray-700 {
      color: var(--color-text-primary);
    }

    .text-gray-900 {
      color: var(--color-button-blue);
    }

    &--container {
      height: 76px;
      border-top: 1px solid rgb(209 213 219 / var(--tw-border-opacity));
    }
  }
}

@media (max-width: 640px) {
  .partners-page {
    .partners__header {
      flex-direction: column;

      &--left {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 12px;

        .left-container {
          width: 35%;
          justify-content: end;

          span {
            display: none;
          }

          &__setting {
            padding: 0;
            margin-right: 19px;
          }

          &__menu {
            padding: 0;
            margin-right: -5px;
          }
        }
      }

      &--right {
        width: 100%;
        justify-content: space-between;
        margin: 0;
        padding: 0;

        .container__dropdown {
          margin: 0;
        }

        & > button {
          width: 100%;
        }
      }
    }

    table {
      thead {
        height: 44px;
      }
    }

    &__pagination {
      &--container {
        .hidden-mobile {
          display: none;
        }
      }
    }
  }
}
