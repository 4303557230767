@import '../../../styles/common/datePicker.scss';

#select-date-range-modal {
  height: 100%;
  color: var(--color-text-primary);

  > div {
    height: auto;
  }

  svg {
    color: var(--color-text-primary);
  }

  .border-b-0 {
    border-bottom: none;

    h3 {
      color: var(--color-text-primary);
      text-align: center;
      width: calc(100% - 1.5rem);
      padding-left: 1.5rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    button {
      margin-top: -0.2rem;
      margin-right: -0.2rem;

      &:hover {
        background-color: var(--color-button-blue);
        box-shadow: none;

        svg {
          transition: all 0.5s ease;
          color: var(--color-text-white);
        }
      }
    }
  }

  label {
    color: var(--color-text-primary);
    font-weight: 500;
    font-size: 14px;
  }

  .date-form {
    @include date-picker;

    [data-testid='date-picker-error'] {
      width: fit-content;
    }
  }
}

@media (max-width: 640px) {
  #add-loan-modal {
    flex-wrap: wrap;
    height: 100%;

    .form-flex-col {
      margin: 0;
      flex-direction: column;

      .date-form {
        margin: 16px 0 0 0;
      }
    }
  }
}
