@import '../../../styles/common/textForm.scss';
@import '../../../styles/__mixin';

#confirm-edit-loan-modal {
  color: var(--color-text-primary);
  height: 100%;
  overflow-y: hidden;

  >div {
    height: auto;
  }


  svg {
    color: var(--color-text-primary);
  }

  .border-b {
    border-bottom: none;

    h3 {
      color: var(--color-text-primary);
      text-align: center;
      width: calc(100% - 32px);
      padding-left: 32px;
      padding-top: 0;
    }

    button {
      margin-top: -1rem;
      margin-right: -1rem;

      &:hover {
        background-color: var(--color-button-blue);
        box-shadow: none;

        svg {
          transition: all 0.5s ease;
          color: var(--color-text-white);
        }
      }
    }
  }

  label {
    color: var(--color-text-primary);
    font-weight: 500;
    font-size: 14px;
  }

  .text-form {
    @include text-form;
  }
}