@mixin text-form {
  textarea {
    padding-left: 15px;
    border-color: '#e5e7eb';
    background-color: var(--color-background-white);
    &:disabled {
      background-color: var(--color-background-disabled-input);
      &.haveValue {
        .text-form__label {
          &.otherBackGroundColor {
            background-color: rgb(253 253 234 / var(--tw-bg-opacity));
          }
        }
      }
    }
  }
  input {
    padding-left: 15px;
    background-color: var(--color-background-white);
    &:disabled {
      background-color: var(--color-background-disabled-input);
    }
  }
  &__label {
    top: 0.85rem;
    &.haveValue {
      top: -13px;
      left: 10px;
      label {
        color: var(--color-text-grey-light);
        font-size: 12px;
      }
      &.otherBackGroundColor {
        background-color: rgb(253 253 234 / var(--tw-bg-opacity));
      }
      &.disabled {
        background-color: var(--color-background-disabled-input);
        border-radius: 4px;
        &.haveValue {
          background-color: var(--color-background-white);
        }
      }
    }
    pointer-events: none;
    left: 10px;
    padding: 0 5px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    &.disabled {
      background-color: var(--color-background-disabled-input);
    }
    label {
      font-weight: normal;
      color: rgba(107, 114, 128, 1);
    }
  }

  [data-testid='test-supporter-checkNumber-error'] {
    width: fit-content;
  }

  &:focus-within {
    .text-form__label {
      display: block;
      top: -13px;
      left: 10px;
      label {
        color: var(--color-text-grey-light);
        font-size: 12px;
      }
      &.otherBackGroundColor {
        background-color: rgb(253 253 234 / var(--tw-bg-opacity));
      }
    }
    .dropdown-heading-value__no-content {
      display: none;
    }
  }
}
