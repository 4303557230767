@import '../__mixin';

@mixin date-picker {

  .react-datepicker-wrapper {
    width: 100%;

    [stroke="currentColor"] {
      transition: all .5s ease;

      &:hover {
        background-color: var(--color-button-blue);
        color: var(--color-text-white);
        box-shadow: none;
        border-radius: 4px;
      }
    }

    .input-group {
      input {
        padding-left: 15px;

        &:disabled {
          background-color: var(--color-background-disabled-input);
        }
      }

      &.error {
        input {
          &:focus {
            --tw-ring-color: transparent;
            border: 2px solid red;
          }

          border: 1px solid red;
        }
      }

      &__label {
        top: 0.9rem;

        &.haveValue {
          display: block;
          top: -14px;
          left: 10px;

          label {
            color: var(--color-text-grey-light);
            font-size: 12px;
          }

          &.disabled {
            background-color: var(--color-background-disabled-input);
            border-radius: 4px;

            &.haveValue {
              background-color: var(--color-background-white);
            }
          }
        }

        pointer-events: none;
        left: 10px;
        padding: 0 5px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;

        &.disabled {
          background-color: var(--color-background-disabled-input);
        }

        label {
          font-weight: normal;
          color: rgba(107, 114, 128, 1);
        }
      }

      &:focus-within {
        .input-group__label {
          display: block;
          top: -14px;
          left: 10px;

          label {
            color: var(--color-text-grey-light);
            font-size: 12px;
          }
        }
      }
    }

    .clear-button-disabled {
      display: none;
    }
  }
  .react-datepicker__tab-loop {
    [aria-selected='true'] {
      color: var(--color-button-blue);
      background-color: var(--color-background-white);
      border-radius: 0;
      border-bottom: 1px solid var(--color-button-blue);
    }

    [aria-selected='true'] {
      color: var(--color-button-blue);
      background-color: var(--color-background-white);
      border-radius: 0;
      border-bottom: 1px solid var(--color-button-blue);
    }

    .react-datepicker__navigation-icon {
      &--previous, &--next {
        top: 4px
      }
    }

    .react-datepicker__header__dropdown--scroll {
      margin-top: 5px;

      .react-datepicker__year-dropdown--scrollable {
        @include scrollYBem;

        a {
          display: none;
        }
      }
    }

    .react-datepicker-popper {
      z-index: 100;
    }
  }
  .react-datepicker-popper {
    z-index: 2000;
  }
}
