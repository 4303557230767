#confirm-modal {
  > div {
    &.max-w-md {
      height: auto;
    }
  }

  [aria-label='Close'] {
    &:hover {
      background-color: var(--color-button-blue);
      box-shadow: none;

      svg {
        transition: all 0.5s ease;
        color: var(--color-text-white);
      }
    }
  }
}
